<template>
    <div
        class="flex lg:items-center flex-col lg:flex-row justify-between py-6 lg:py-11 px-4 lg:px-15 bg-orange bg-opacity-5"
    >
        <h3 class="lg:w-1/2 mb-2 lg:mb-0 font-Open-Sans-Bold text-orange text-sm lg:text-2xl">
            <T t="forgotten-pin.title" />
        </h3>
        <div
            class="lg:w-1/2 leading-6 font-Open-Sans-Regular text-base text-sm lg:text-lg"
            v-html="$t('forgotten-pin.description')"
        ></div>
    </div>
</template>

<script>
export default {
    name: 'ForgottenPinSection',
};
</script>
