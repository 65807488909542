<template>
    <div class="relative">
        <h3 class="section-title lg:text-center mt-16 lg:mt-20 mb-9 lg:mb-15">
            <T t="section-title.customer-reviews" />
        </h3>
        <div id="customer-review-carousel" class="swiper">
            <div class="swiper-wrapper">
                <div v-for="review in reviews" :key="`review-${review.id}`" class="swiper-slide">
                    <div class="flex flex-col lg:items-start">
                        <div class="flex">
                            <div class="rounded-full overflow-hidden min-w-[6rem] min-h-[6rem] mr-4.5 lg:mr-9 flex">
                                <img class="object-cover w-24 h-24" :src="review.image" :alt="review.name" />
                            </div>
                            <div class="flex flex-col">
                                <h4 class="text-base font-Open-Sans-Bold mb-4.5 lg:mb-3">{{ review.name }}</h4>
                                <h5 class="text-sm font-Source-Sans-Pro-Regular">{{ review.position }}</h5>
                                <h5 class="text-sm font-Source-Sans-Pro-Regular mb-5">{{ review.company }}</h5>
                            </div>
                        </div>
                        <div class="flex flex-col lg:ml-33 mt-4.5 lg:mt-0">
                            <h6 class="text-sm lg:text-base font-Source-Sans-Pro-Regular leading-6">
                                „{{ review.content }}”
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="reviews.length > 2" class="flex mt-8 lg:mt-14 items-center gap-x-6 justify-center lg:justify-end">
            <div class="swiper-pagination"></div>
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TestimonialCarousel',
    props: {
        reviews: {
            type: Array,
            required: true,
        },
    },
    mounted() {
        this.customerReviewCarousel = new this.$swiper('#customer-review-carousel', {
            slidesPerView: 2,
            spaceBetween: 48,
            resistance: true,
            resistanceRatio: 0,
            speed: 750,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true,
            },
            breakpoints: {
                250: {
                    slidesPerView: 1,
                    spaceBetween: 60,
                },
                992: {
                    slidesPerView: 2,
                    spaceBetween: 104,
                },
            },
            loop: false,
            autoplay: {
                delay: 200000,
            },
        });
    },
    beforeDestroy() {
        if (this.customerReviewCarousel) {
            this.customerReviewCarousel.destroy();
        }
    },
};
</script>
