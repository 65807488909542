<template>
    <div class="grid grid-cols-2 gap-y-4.5 lg:gap-y-0 lg:gap-x-10">
        <div class="col-span-2 lg:col-span-1">
            <div class="py-8 px-4 lg:px-15 bg-grey-100 h-full">
                <h4 class="section-title mb-2">
                    {{ price }} / <span><T t="generic.school_year" /></span>
                </h4>
                <span v-if="$i18n.locale === 'hr' || kuna_price" class="mb-4 font-Exo-Bold text-lg">
                    {{ kuna_price }}
                </span>
                <h5 class="font-Open-Sans-Bold mb-2 text-sm lg:text-lg"><T t="generic.price-subtitle" /></h5>
                <div class="flex flex-col">
                    <div
                        v-for="(detail, i) in subscriptionDetails"
                        :key="`subscription-detail-${i}`"
                        class="py-4 pl-4 lg:pl-0 lg:py-7 border-b"
                        :class="i + 1 !== subscriptionDetails.length ? 'border-grey-200' : 'border-transparent'"
                    >
                        <h6>
                            <span class="subscription-detail flex items-center text-sm lg:text-lg">{{ detail }}</span>
                        </h6>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-span-2 lg:col-span-1">
            <div class="py-8 px-4 lg:px-15 bg-grey-100 h-full">
                <h4 class="section-title mb-2"><T t="registration-process.title" /></h4>
                <h5 class="font-Open-Sans-Bold text-sm lg:text-lg mb-7">
                    <T t="registration-process.subtitle" />
                </h5>
                <div
                    class="font-Open-Sans-Regular mb-5 lg:mb-16 text-sm lg:text-lg"
                    v-html="$t('registration-process.description')"
                ></div>
                <PButton to="registration" :label="$t('generic.registration')" blue lg />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PricingSection',
    props: {
        price: {
            type: String,
            required: true,
        },
        kuna_price: {
            type: String,
            required: false,
            default: null,
        },
        subscriptionDetails: {
            type: Array,
            required: false,
            default: null,
        },
    },
};
</script>

<style scoped>
.subscription-detail {
    &::before {
        content: '';
        @apply bg-no-repeat bg-center bg-center w-[14px] h-3 mr-[18px];
        background-image: url('~/assets/images/icons/checkmark.svg');
    }
}
</style>
