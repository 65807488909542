<template>
    <main class="pb-12 lg:pb-25">
        <Hero
            v-if="heroSlides.length"
            :title="heroSlides[0].title"
            :subtitle="heroSlides[0].content"
            :button-label="heroSlides[0].button_label"
            :button-type="heroSlides[0].button_type"
            :image="heroSlides[0].image"
            :mobile-image="heroSlides[0].mobile_image"
            :button-url="heroSlides[0].button_url"
        />
        <div class="container mt-12 lg:mt-22">
            <div class="relative">
                <div id="benefits" class="index-section"></div>
            </div>
            <div v-if="advantages.children.length" class="grid grid-cols-12 lg:gap-x-16 gap-y-12 lg:gap-y-0 relative">
                <div v-if="advantages.children.length" class="col-span-12 lg:col-span-6 flex flex-col justify-between">
                    <div>
                        <h3 class="section-title lg:text-center lg:mb-10">
                            <T t="section-title.benefits-for-kids" />
                        </h3>
                        <AdvantageCard
                            v-for="(benefit, i) in advantages.children"
                            :key="`benefit-${i}`"
                            :title="benefit.title"
                            :desc="benefit.content"
                            :border="i + 1 !== advantages.children.length"
                        />
                    </div>

                    <PButton
                        lg
                        class="text-center w-max mx-auto"
                        to="registration"
                        :label="$t('generic.registration')"
                        blue
                    />
                </div>
                <div v-if="advantages.schools.length" class="col-span-12 lg:col-span-6 flex flex-col justify-between">
                    <div>
                        <h3 class="section-title lg:text-center lg:mb-10">
                            <T t="section-title.benefits-for-schools" />
                        </h3>
                        <AdvantageCard
                            v-for="(benefit, i) in advantages.schools"
                            :key="`benefit-${i}`"
                            :title="benefit.title"
                            :desc="benefit.content"
                            :border="i + 1 !== advantages.schools.length"
                        />
                    </div>

                    <PButton
                        lg
                        class="text-center w-max mx-auto"
                        to="contact-for-schools"
                        :label="$t('header.contact')"
                        blue
                    />
                </div>
            </div>
            <div v-if="lockers.length" class="relative">
                <div id="product" class="index-section"></div>
                <h3 class="section-title lg:text-center mt-12 lg:mt-20 mb-0 lg:mb-16">
                    <T t="generic.product-title" />
                </h3>
            </div>
            <ProductSection v-if="lockers.length" :product-details="lockers" />
            <ForgottenPinSection class="lg:mt-8" />
            <div v-if="model.brutto_price" class="relative">
                <div id="prices" class="index-section"></div>
                <h3 class="section-title lg:text-center mt-12 lg:mt-20 mb-4.5 lg:mb-14">
                    <T t="section-title.pricing" />
                </h3>
            </div>
            <PricingSection
                v-if="$store.state.data.price"
                :price="$store.state.data.price"
                :kuna_price="model.brutto_kuna_price"
                :subscription-details="subscriptionDetails"
            />
            <TestimonialCarousel v-if="testimonials.length" :reviews="testimonials" />
        </div>
    </main>
</template>

<script>
import Hero from '@/components/Hero';
import AdvantageCard from '@/components/Cards/AdvantageCard.vue';
import ProductSection from '@/components/Sections/ProductSection';
import ForgottenPinSection from '@/components/Sections/ForgottenPinSection';
import PricingSection from '@/components/Sections/PricingSection';
import TestimonialCarousel from '@/components/TestimonialCarousel.vue';

export default {
    name: 'Index',
    components: {
        Hero,
        AdvantageCard,
        ProductSection,
        ForgottenPinSection,
        PricingSection,
        TestimonialCarousel,
    },
    async asyncData({ $axios, i18n }) {
        let model = [];
        let heroSlides = [];
        let testimonials = [];
        let lockers = [];
        const advantages = {
            children: [],
            schools: [],
        };

        try {
            await Promise.allSettled([
                $axios.$get(''),
                $axios.$get('/headline'),
                $axios.$get('/advantage'),
                $axios.$get('/testimonial'),
                $axios.$get('/locker-info'),
            ])
                .then(([modelRes, heroRes, advantagesRes, testimonialRes, lockerInfoRes]) => {
                    if (modelRes.status === 'fulfilled' && modelRes.value.success === 1) {
                        model = modelRes.value.data;
                    }
                    if (heroRes.status === 'fulfilled' && heroRes.value.success === 1) {
                        heroSlides = heroRes.value.data;
                    }
                    if (advantagesRes.status === 'fulfilled' && advantagesRes.value.success === 1) {
                        advantages.children = advantagesRes.value.data.children;
                        advantages.schools = advantagesRes.value.data.schools;
                    }
                    if (testimonialRes.status === 'fulfilled' && testimonialRes.value.success === 1) {
                        testimonials = testimonialRes.value.data;
                    }
                    if (lockerInfoRes.status === 'fulfilled' && lockerInfoRes.value.success === 1) {
                        lockers = lockerInfoRes.value.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        }
        return {
            model,
            heroSlides,
            advantages,
            testimonials,
            lockers,
        };
    },
    data() {
        return {
            subscriptionDetails: [
                this.$t('rental_details.benefit_1'),
                this.$t('rental_details.benefit_2'),
                this.$t('rental_details.benefit_3'),
                this.$t('rental_details.benefit_4'),
            ],
        };
    },
};
</script>

<style scoped>
#benefits,
#product,
#prices {
    @apply absolute left-0 top-[-8rem];
}
</style>
