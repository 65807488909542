<template>
    <div class="grid grid-cols-12 lg:gap-x-10">
        <div v-if="$device.isDesktop" class="col-span-12 lg:col-span-5">
            <div class="aspect-w-2 aspect-h-3" :class="{ 'fade-in': fadeInImage, 'fade-out': fadeOutImage }">
                <img
                    class="max-h-[75vh] w-full h-auto mx-auto object-contain"
                    :src="currentImage"
                    :alt="productDetails[0].title"
                />
            </div>
        </div>
        <div class="col-span-12 lg:col-span-7">
            <div class="flex flex-col lg:gap-y-7">
                <div
                    v-for="(detail, i) in productDetails"
                    :key="`detail-${i}`"
                    class="py-4 lg:p-6 lg:border border-grey-200 rounded-lg hover:border-grey-900 transition"
                    @mouseenter="changeImage(detail.image)"
                    @mouseleave="isChanging = currentImage"
                >
                    <img v-if="$device.isMobile" :src="detail.image" class="w-full lg:w-3/12 mb-4" alt="Cabinet" />

                    <h4 class="font-Open-Sans-Bold text-sm lg:text-lg mb-2">{{ detail.title }}</h4>
                    <div>
                        <div
                            class="font-Open-Sans-Regular text-sm lg:text-lg leading-5 lg:leading-7 text-block"
                            v-html="detail.content"
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProductSection',
    props: {
        productDetails: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            currentImage: this.productDetails[0].image,
            isChanging: this.productDetails[0].image,
            fadeInImage: false,
            fadeOutImage: false,
        };
    },
    watch: {
        isChanging() {
            this.fadeOutImage = true;
            setTimeout(() => {
                this.fadeOutImage = false;
                this.fadeInImage = true;
            }, 250);
        },
    },
    methods: {
        changeImage(image) {
            this.isChanging = image;
            setTimeout(() => {
                this.currentImage = image;
            }, 250);
        },
    },
};
</script>

<style>
@keyframes fadeIn {
    from {
        @apply opacity-0;
        transform: translateX(-1.5rem);
    }
    to {
        @apply opacity-100;
        transform: translateX(0);
    }
}
@keyframes fadeOut {
    from {
        @apply opacity-100;
        transform: translateX(0);
    }
    to {
        @apply opacity-0;
        transform: translateX(-0.5rem);
    }
}
.fade-in {
    animation: fadeIn 0.25s forwards;
}

.fade-out {
    animation: fadeOut 0.25s forwards;
}
.text-block {
    a {
        @apply font-Open-Sans-Bold text-blue-100 underline;
    }
}
</style>
