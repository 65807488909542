<template>
    <div
        class="flex items-start flex-col lg:flex-row py-8 border-b lg:min-h-[9rem]"
        :class="border ? 'border-grey-200' : 'border-transparent'"
    >
        <h4 class="text-sm lg:text-lg font-Open-Sans-Bold w-full lg:w-4/12 mb-2 lg:mb-0">{{ title }}</h4>
        <h5 class="text-sm lg:text-lg font-Open-Sans-Regular leading-6 w-full lg:w-8/12">{{ desc }}</h5>
    </div>
</template>

<script>
export default {
    name: 'AdvantageCard',
    props: {
        title: {
            type: String,
            required: true,
        },
        desc: {
            type: String,
            required: false,
            default: null,
        },
        border: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style scoped></style>
