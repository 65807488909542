<template>
    <div class="h-[calc(100vh+3.75rem)] xl:h-[calc(100vh+6.25rem)] flex items-center justify-center">
        <img class="w-full h-full object-cover relative" :src="$device.isDesktop ? image : mobileImage" :alt="title" />
        <div class="container absolute">
            <div class="grid grid-cols-12 text-white">
                <div class="col-span-12 lg:col-span-7">
                    <h1 class="text-[1.875rem] lg:text-[3.375rem] leading-8 lg:leading-[4rem] font-Exo-Bold mb-4 title">
                        {{ title }}
                    </h1>
                    <h2 class="mb-5 font-Open-Sans-Regular text-lg">
                        {{ subtitle }}
                    </h2>
                    <PButton
                        v-if="buttonUrl && buttonLabel"
                        :to="buttonUrl"
                        :label="buttonLabel"
                        lg
                        blue
                        :external="buttonType === 2"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Hero',
    props: {
        title: {
            type: String,
            required: false,
            default: null,
        },
        image: {
            type: String,
            required: false,
            default: null,
        },
        mobileImage: {
            type: String,
            required: false,
            default: null,
        },
        subtitle: {
            type: String,
            required: false,
            default: null,
        },
        buttonLabel: {
            type: String,
            required: false,
            default: null,
        },
        buttonType: {
            type: Number,
            required: true,
        },
        buttonUrl: {
            type: String,
            required: false,
            default: null,
        },
    },
};
</script>

<style scoped>
.title {
    text-shadow: 1px 2px 0.5px rgba(0, 0, 0, 0.3);
}
img {
    &::before {
        content: '';
        @apply w-full h-full absolute top-0 left-0;
        background: linear-gradient(1.26deg, rgba(0, 0, 0, 0.5) 1.01%, rgba(0, 0, 0, 0) 59.39%), rgba(0, 0, 0, 0.25);
    }
}
</style>
